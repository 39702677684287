// import React, { useEffect, useState } from 'react';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import { format } from 'date-fns';

// const Payment = () => {
//     const [paymentData, setPaymentData] = useState([]);
//     const [originalData, setOriginalData] = useState([]); // New state for original data
//     const [selectedDate, setSelectedDate] = useState(null); // Change to null for easier handling
//     const [searchError, setSearchError] = useState('');
//     const [selectedAppName, setSelectedAppName] = useState(''); // New state for selected app name


//     const fetchData = async () => {
//         try {
//             const response = await fetch('https://tronixpayment.axispay.cloud/api/v1/getpayment');
//             if (response.ok) {
//                 const data = await response.json();
//                 const finaldata = data.data.payments;

//                 setPaymentData(finaldata);
//                 setOriginalData(finaldata); // Set original data
//                 setSearchError(''); // Clear search error when new data is fetched
//             }
//         } catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     };

//     const handleSearch = () => {
//         setSearchError('');

//         if (!selectedDate) {
//             setSearchError('Please select a date to search.');
//             return;
//         }

//         const formattedDate = format(selectedDate, 'yyyy-MM-dd');
//         console.log('formattedDate =>', formattedDate);

//         const filteredData = originalData.filter(payment => payment.date === formattedDate);

//         if (filteredData.length === 0) {
//             setSearchError('No records found for the selected date.');
//         }

//         setPaymentData(filteredData);
//     };

//     const handleDateChange = (date) => {
//         setSelectedDate(date);

//         // Check if the date is cleared
//         if (!date) {
//             setPaymentData(originalData); // Show all records if the date is cleared
//         }
//     };

//     const handleAppNameChange = async (event) => {
//         const appName = event.target.value;
//         setSelectedAppName(appName);

//         if (appName) {
//             try {
//                 const response = await fetch(`https://tronixpayment.axispay.cloud/api/v1/appnamewiseget?appName=${appName}`);
//                 if (response.ok) {
//                     const data = await response.json();
//                     const finaldata = data.data.payments; // Adjust according to your API response structure
//                     setPaymentData(finaldata);
//                 } else {
//                     console.error('Error fetching app name data:', response.status);
//                     setPaymentData([]); // Clear payment data on error
//                 }
//             } catch (error) {
//                 console.error('Error fetching app name data:', error);
//                 setPaymentData([]); // Clear payment data on error
//             }
//         } else {
//             // If no app name is selected, reset to original data
//             setPaymentData(originalData);
//         }
//     };

//     useEffect(() => {
//         fetchData();
//     }, []);

//     // Calculate total transactions and total amount
//     const totalTransactions = paymentData.length;
//     const totalAmount = paymentData.reduce((sum, payment) => sum + Number(payment.amount), 0);


//     return (
//         <div className="flex flex-col main-container px-4 text-sm">
//             <div className="">

//                 <div className="mt-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6">
//                     {/* First Grid: Date Picker */}
//                     <div className="relative max-w-full sm:max-w-none px-6">

//                         <DatePicker
//                             className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg text-sm block w-full p-2.5 pr-72 sm:pr-80 md:pr-[27rem] lg:pr-[8rem] xl:pr-[18rem] 2xl:pr-[20.5rem]" // Adjusted padding for different screen sizes
//                             placeholderText="Select date"
//                             selected={selectedDate}
//                             onChange={handleDateChange}
//                             dateFormat="yyyy-MM-dd"
//                         />

//                         <button
//                             type="button"
//                             className="absolute inset-y-0 right-[5rem] flex items-center px-3 bgsearch text-white text-sm rounded-r-lg"
//                             onClick={handleSearch}
//                         >
//                             Search
//                         </button>
//                     </div>

//                     {/* Second Grid: Selection Box */}
//                     <div className="px-6">
//                         <select
//                             className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg text-sm block w-full p-2.5 shadow-md transition duration-150 ease-in-out"
//                             value={selectedAppName}
//                             onChange={handleAppNameChange}
//                         >
//                             <option value="" className="text-gray-500">Select an option</option>
//                             {Array.from(new Set(paymentData.map(item => item.appName))).map((uniqueAppName, index) => (
//                                 <option key={index} value={uniqueAppName}>
//                                     {uniqueAppName}
//                                 </option>
//                             ))}
//                         </select>
//                     </div>
//                 </div>

//                 {/* Second Section: Total Transaction and Total Amount */}
//                 <div className="mt-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6">
//                     <div className="px-6 mt-4">
//                         <p className="text-black">Total Transaction:
//                             <span className="bg-green-300 px-2 py-1 rounded-full font-bold ml-2">{paymentData.length}</span>
//                         </p>
//                     </div>

//                     <div className="px-6 mt-4 float-right">
//                         <p className="text-black">Total Amount:
//                             <span className="bg-green-300 px-2 py-1 rounded-full font-bold ml-2">₹ {totalAmount}</span>
//                         </p>
//                     </div>
//                 </div>


//                 <div className="p-6 overflow-hidden px-0">


//                     <table className="w-full min-w-max table-auto text-left text-black">
//                         <thead>
//                             <tr>
//                                 <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
//                                     <p className="antialiased font-sans text-sm text-blue-gray-900 flex items-center justify-between gap-2 font-normal leading-none opacity-70">Id</p>
//                                 </th>
//                                 <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
//                                     <p className="antialiased font-sans text-sm text-blue-gray-900 flex items-center justify-between gap-2 font-normal leading-none opacity-70">Date</p>
//                                 </th>
//                                 <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
//                                     <p className="antialiased font-sans text-sm text-blue-gray-900 flex items-center justify-between gap-2 font-normal leading-none opacity-70">Time</p>
//                                 </th>
//                                 <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
//                                     <p className="antialiased font-sans text-sm text-blue-gray-900 flex items-center justify-between gap-2 font-normal leading-none opacity-70">Amount</p>
//                                 </th>
//                                 <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
//                                     <p className="antialiased font-sans text-sm text-blue-gray-900 flex items-center justify-between gap-2 font-normal leading-none opacity-70">App Name</p>
//                                 </th>
//                                 <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
//                                     <p className="antialiased font-sans text-sm text-blue-gray-900 flex items-center justify-between gap-2 font-normal leading-none opacity-70">Package Name</p>
//                                 </th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {paymentData.length === 0 ? (
//                                 <tr>
//                                     <td colSpan="6" className="text-center py-4">
//                                         No records found.
//                                     </td>
//                                 </tr>
//                             ) : (
//                                 paymentData.map((payment, index) => (
//                                     <tr key={index}>
//                                         <td className="p-4 border-b border-blue-gray-50">
//                                             <div className="flex items-center gap-3">
//                                                 <div className="flex flex-col">
//                                                     <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-normal">
//                                                         {index + 1}
//                                                     </p>
//                                                 </div>
//                                             </div>
//                                         </td>
//                                         <td className="p-4 border-b border-blue-gray-50">
//                                             <div className="flex items-center gap-3">
//                                                 <div className="flex flex-col">
//                                                     <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-normal">
//                                                         {payment.date}
//                                                     </p>
//                                                 </div>
//                                             </div>
//                                         </td>
//                                         <td className="p-4 border-b border-blue-gray-50">
//                                             <div className="flex items-center gap-3">
//                                                 <div className="flex flex-col">
//                                                     <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-normal">
//                                                         {payment.time}
//                                                     </p>
//                                                 </div>
//                                             </div>
//                                         </td>
//                                         <td className="p-4 border-b border-blue-gray-50">
//                                             <div className="flex flex-col">
//                                                 <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">
//                                                     Rs.{payment.amount}
//                                                 </p>
//                                             </div>
//                                         </td>
//                                         <td className="p-4 border-b border-blue-gray-50">
//                                             <div className="w-max">
//                                                 <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">
//                                                     {payment.appName}
//                                                 </p>
//                                             </div>
//                                         </td>
//                                         <td className="p-4 border-b border-blue-gray-50">
//                                             <div className="w-max">
//                                                 <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">
//                                                     {payment.packageName}
//                                                 </p>
//                                             </div>
//                                         </td>
//                                     </tr>
//                                 ))
//                             )}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Payment;

// today code
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

const Payment = () => {
    const [paymentData, setPaymentData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [searchError, setSearchError] = useState('');
    const [selectedAppName, setSelectedAppName] = useState('');

    const fetchData = async () => {
        try {
            const response = await fetch('https://tronixpayment.axispay.cloud/api/v1/getpayment');
            if (response.ok) {
                const data = await response.json();
                const finaldata = data.data.payments;

                setPaymentData(finaldata);
                setOriginalData(finaldata);
                setSearchError('');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSearch = () => {
        if (!selectedDate) {
            setSearchError('Please select a date to search.');
            return;
        }

        const formattedDate = format(selectedDate, 'yyyy-MM-dd');
        const filteredData = originalData.filter(payment => payment.date === formattedDate);

        if (filteredData.length === 0) {
            setSearchError('No records found for the selected date.');
        }

        setPaymentData(filteredData);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);

        if (date) {
            handleSearch(); // Automatically trigger search on date change
        } else {
            setPaymentData(originalData); // Show all records if the date is cleared
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const handleAppNameChange = async (event) => {
        const appName = event.target.value;
        setSelectedAppName(appName);

        if (appName) {
            try {
                const response = await fetch(`https://tronixpayment.axispay.cloud/api/v1/appnamewiseget?appName=${appName}`);
                if (response.ok) {
                    const data = await response.json();
                    const finaldata = data.data.payments;
                    setPaymentData(finaldata);
                } else {
                    console.error('Error fetching app name data:', response.status);
                    setPaymentData([]);
                }
            } catch (error) {
                console.error('Error fetching app name data:', error);
                setPaymentData([]);
            }
        } else {
            setPaymentData(originalData);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const totalTransactions = paymentData.length;
    const totalAmount = paymentData.reduce((sum, payment) => sum + Number(payment.amount), 0);

    return (

        <div className="flex flex-col main-container px-4 text-sm">
            <div className="">


                <div className="mt-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {/* First Grid: Date Picker */}
                    <div className="relative max-w-full sm:max-w-none px-6">
                        <DatePicker
                            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg text-sm block w-full p-2.5 pr-64 " // Adjusted padding for different screen sizes
                            placeholderText="Select date"
                            selected={selectedDate}
                            onChange={handleDateChange}
                            onKeyDown={handleKeyDown}
                            dateFormat="yyyy-MM-dd"
                        />
                    </div>

                    {/* Second Grid: Selection Box */}
                    <div className="px-6">
                        <select
                            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg text-sm block w-full p-2.5 shadow-md transition duration-150 ease-in-out"
                            value={selectedAppName}
                            onChange={handleAppNameChange}
                        >
                            <option value="" className="text-gray-500">Select an option</option>
                            {Array.from(new Set(paymentData.map(item => item.appName))).map((uniqueAppName, index) => (
                                <option key={index} value={uniqueAppName}>
                                    {uniqueAppName}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Third Grid: Search Button */}
                    <div className="px-6 flex items-center justify-center">
                        {selectedDate && selectedAppName && (
                            <button
                                type="button"
                                className="w-full bg-blue-500 text-white p-2 rounded-lg"
                                onClick={handleSearch}
                            >
                                Search
                            </button>
                        )}
                    </div>
                </div>
                {/* Second Section: Total Transaction and Total Amount */}
                <div className="mt-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6">
                    <div className="px-6 mt-4">
                        <p className="text-black">Total Transaction:
                            <span className="bg-green-300 px-2 py-1 rounded-full font-bold ml-2">{paymentData.length}</span>
                        </p>
                    </div>

                    <div className="px-6 mt-4 float-right">
                        <p className="text-black">Total Amount:
                            <span className="bg-green-300 px-2 py-1 rounded-full font-bold ml-2">₹ {totalAmount}</span>
                        </p>
                    </div>
                </div>


                <div className="p-6 overflow-hidden px-0">


                    <table className="w-full min-w-max table-auto text-left text-black">
                        <thead>
                            <tr>
                                <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
                                    <p className="antialiased font-sans text-sm text-blue-gray-900 flex items-center justify-between gap-2 font-normal leading-none opacity-70">Id</p>
                                </th>
                                <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
                                    <p className="antialiased font-sans text-sm text-blue-gray-900 flex items-center justify-between gap-2 font-normal leading-none opacity-70">Date</p>
                                </th>
                                <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
                                    <p className="antialiased font-sans text-sm text-blue-gray-900 flex items-center justify-between gap-2 font-normal leading-none opacity-70">Time</p>
                                </th>
                                <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
                                    <p className="antialiased font-sans text-sm text-blue-gray-900 flex items-center justify-between gap-2 font-normal leading-none opacity-70">Amount</p>
                                </th>
                                <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
                                    <p className="antialiased font-sans text-sm text-blue-gray-900 flex items-center justify-between gap-2 font-normal leading-none opacity-70">App Name</p>
                                </th>
                                <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
                                    <p className="antialiased font-sans text-sm text-blue-gray-900 flex items-center justify-between gap-2 font-normal leading-none opacity-70">Package Name</p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {paymentData.length === 0 ? (
                                <tr>
                                    <td colSpan="6" className="text-center py-4">
                                        No records found.
                                    </td>
                                </tr>
                            ) : (
                                paymentData.map((payment, index) => (
                                    <tr key={index}>
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <div className="flex items-center gap-3">
                                                <div className="flex flex-col">
                                                    <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-normal">
                                                        {index + 1}
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <div className="flex items-center gap-3">
                                                <div className="flex flex-col">
                                                    <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-normal">
                                                        {payment.date}
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <div className="flex items-center gap-3">
                                                <div className="flex flex-col">
                                                    <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-normal">
                                                        {payment.time}
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <div className="flex flex-col">
                                                <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">
                                                    Rs.{payment.amount}
                                                </p>
                                            </div>
                                        </td>
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <div className="w-max">
                                                <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">
                                                    {payment.appName}
                                                </p>
                                            </div>
                                        </td>
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <div className="w-max">
                                                <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">
                                                    {payment.packageName}
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Payment;


