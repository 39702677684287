import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { format } from 'date-fns';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TotalTransaction = () => {
  const [paymentData, setPaymentData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState(0);

  const fetchData = async () => {
    try {
      const response = await fetch('https://tronixpayment.axispay.cloud/api/v1/getpayment');
      if (response.ok) {
        const data = await response.json();
        const finaldata = data.data.payments;

        setPaymentData(finaldata);

        // Calculate total amount
        const total = finaldata
          .map((el) => Number(el.amount)) // Convert each amount to a number
          .reduce((acc, curr) => acc + curr, 0); // Sum the amounts

        setTotalAmount(total);
        setTotalTransactions(finaldata.length); // Set the total number of transactions
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Function to validate and format the payment date
  const parsePaymentDate = (paymentDate) => {
    const date = paymentDate;
    return date;
  };

  // Group transactions by date
  const groupByDate = paymentData.reduce((acc, curr) => {
    const formattedDate = parsePaymentDate(curr.date); // Validate and format the date
    if (formattedDate !== 'Invalid Date') {
      if (!acc[formattedDate]) {
        acc[formattedDate] = {
          transactionCount: 0,
          totalAmount: 0,
        };
      }
      acc[formattedDate].transactionCount += 1;
      acc[formattedDate].totalAmount += Number(curr.amount);
    }
    return acc;
  }, {});

  // Group transactions by app
  // Group transactions by app
  const groupByApp = paymentData.reduce((acc, curr) => {
    const app = curr.appName; // Assuming `appName` is the field containing the app information
    if (!acc[app]) {
      acc[app] = 0;
    }
    acc[app] += 1; // Increment the count for each occurrence of the app
    return acc;
  }, {});


  // Prepare data for the date-wise bar chart
  const dateChartData = {
    labels: Object.keys(groupByDate), // X-axis labels (dates)
    datasets: [
      {
        label: 'Total Transaction Amount',
        data: Object.values(groupByDate).map((data) => data.totalAmount), // Y-axis data (amounts)
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'Number of Transactions',
        data: Object.values(groupByDate).map((data) => data.transactionCount), // Y-axis data (transaction count)
        backgroundColor: 'rgba(255, 159, 64, 0.6)',
        borderColor: 'rgba(255, 159, 64, 1)',
        borderWidth: 1,
      },
    ],
  };

  // Prepare data for the app-wise bar chart
  const appChartData = {
    labels: Object.keys(groupByApp), // X-axis labels (app names)
    datasets: [
      {
        label: 'Total Payment Amount per App',
        data: Object.values(groupByApp), // Y-axis data (amounts per app)
        backgroundColor: 'rgba(153, 102, 255, 0.6)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <main className="main-container">
      <div className="p-5">
        <div className="main-cards">
          <div className="relative p-5 bg-gradient-to-r from-[#581c87] to-[#a78bfa] rounded-md overflow-hidden">
            <div className="relative z-10 mb-4 text-white text-4xl leading-none font-semibold">{totalTransactions}</div>
            <div className="relative z-10 text-white leading-none font-semibold">Total Transactions</div>
          </div>

          <div className="relative p-5 bg-gradient-to-r from-blue-400 to-blue-600 rounded-md overflow-hidden">
            <div className="relative z-10 mb-4 text-white text-4xl leading-none font-semibold">Rs.{totalAmount}</div>
            <div className="relative z-10 text-white leading-none font-semibold">Total Amount</div>
          </div>
        </div>
      </div>

      {/* Chart Section */}
      <div className='flex space-x-4'>
        {/* Date-wise Chart Section */}
        <div className="relative p-5 bg-white rounded-md overflow-hidden w-1/2">
          <h3 className="text-xl font-semibold mb-4">Date-wise Transactions</h3>
          <Bar data={dateChartData} options={chartOptions} />
        </div>

        {/* App-wise Chart Section */}
        <div className="relative p-5 bg-white rounded-md overflow-hidden w-1/2">
          <h3 className="text-xl font-semibold mb-4">App-wise Payments</h3>
          <Bar data={appChartData} options={chartOptions} />
        </div>
      </div>


    </main>
  );
};

export default TotalTransaction;
